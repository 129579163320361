import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import axios from "axios";
import { apiurl } from "../config/config";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchData } from "../redux/slices/diagnosPatient";
import SkeletonContainer from "../routes/skeleton";
import { useFormatDate, useFormatOnlyDate } from "../hooks/useFormatDate";
import moment from "moment";
import { GoDownload } from "react-icons/go";
import ExportDataHandler from "../components/exportDataHandler";
import ReactPaginate from "react-paginate";

const Appointment = () => {
  const [appointmentData, setAppointmentData] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState(null); 
  const [currentPage, setCurrentPage] = useState(1);
   const [totalPages,setTotalPages] = useState(0);
  const itemsPerPage = 50; 

  const [date, setDate] = useState({
    from: "",
    till: "",
  });

  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${apiurl}/api/v1/appointment/getAll?page=${currentPage}&limit=${itemsPerPage}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.success === true) {
          setAppointmentData(response.data.data);
          console.log("///////////////////////////",response.data.data)
          setFilteredData(response.data.data); 
          dispatch(fetchData(response.data.data));
          setTotalPages(response.data.pagination.totalPages)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage]);

  const getCurrentDate = () => {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    const padZero = (num) => (num < 10 ? `0${num}` : num);
    let formattedDate = `${padZero(day)}-${padZero(month)}-${year}`;
    return formattedDate.split("-").reverse().join("-");
  };

  const FormateDate = (date) => useFormatDate(date);
  const FormateOnlyDate = (date) => useFormatOnlyDate(date);

  const dateHandler = (e) => {
    const { name, value } = e.target;
    setDate({ ...date, [name]: value });
  };

  const formattedDate = (date) => moment(date).format("YYYY-MM-DD ");

  useEffect(() => {
    if (date.from && date.till) {
      FilterHandler(); 
    }
  }, [date]);
 

  const FilterHandler = () => {
    if (appointmentData) {
      const fromDate = new Date(date.from);
      const tillDate = new Date(date.till);

      const filtered = appointmentData.filter((item) => {
        const appointmentData = new Date(
          moment(item?.diagonse_date).format("YYYY-MM-DD")
        );
        return appointmentData >= fromDate && appointmentData <= tillDate;
      });

      setFilteredData(filtered);
      setCurrentPage(0); 
    }
  };

  const ExportPayload = (data) => {
    const payload = Array.isArray(data) ? data : [data];
    return payload.map((item) => {
      return {
        _id: item._id,
        name: item.name,
        email: item.email,
        phone: item.phone,
        date: item.date,
        time:item.time,
        status:item.status,
        createdAt: item.createdAt || "",
        updatedAt: item.updatedAt || "",
      };
    });
  };

  // const startIndex = currentPage * itemsPerPage;
  // const paginatedData =
  //   filteredData && filteredData.slice(startIndex, startIndex + itemsPerPage);

    const handleNextPage = () => {
      if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };
  
    const handlePrevPage = () => {
      if (currentPage > 1) setCurrentPage(currentPage - 1);
    };
  return (
    <Layout>
      <div className="w-full mt-2 h-full overflow-y-auto">
        <div className="my-3 mt-6 flex justify-end relative ">
          
          <div className="flex gap-3 max-[375px]:justify-between ">
            <input
              placeholder="from"
              name="from"
              type="date"
              max={getCurrentDate()}
              className="outline-none border border-green-800 rounded-lg md:text-sm text-xs px-0.5 max-[375px]:w-full max-[375px]:px-3 sm:px-2 lg:px-5 py-2 sm:w-auto"
              onChange={dateHandler}
              value={date.from}
            />
            <input
              placeholder="till"
              name="till"
              type="date"
              min={date.from}
              max={getCurrentDate()}
              className="outline-none border border-green-800 rounded-lg md:text-sm text-xs px-0.5 max-[375px]:w-full max-[375px]:px-3 sm:px-2 lg:px-5 py-2 sm:w-auto"
              onChange={dateHandler}
              value={date.till}
            />
          </div>
          <button
             className={`border p-2 px-4 ms-2 rounded-md ${
              date.from && date.till && filteredData.length > 0
                ? "bg-green-50 border-green-800 text-green-800"
                : "border-green-800 text-green-800"
            }`}
            onClick={() => ExportDataHandler(ExportPayload(appointmentData))}
            disabled={date.from === "" && date.till === ""}
          >
            <GoDownload />
          </button>
        </div>

        {/* Table */}
        <div className="h-[75%] overflow-scroll text-xs md:text-sm lg:text-base px-5">
          <table className="border-collapse w-full relative table-auto overflow-auto border">
            <thead className="bg-green-900 border text-white lg:text-base text-xs sticky top-0">
              <tr>
                <th
                  className="px-4 py-2 sm:py-3 sm:px-6 text-center pl-5"
                  style={{ paddingLeft: 10, paddingRight: 10 }}
                >
                  Name
                </th>
                <th
                  className="px-4 py-2 sm:py-3 sm:px-6 text-center pl-5"
                  style={{ paddingLeft: 10, paddingRight: 10 }}
                >
                  Email
                </th>
                <th
                  className="px-4 py-2 sm:py-3 sm:px-6 text-center pl-5"
                  style={{ paddingLeft: 10, paddingRight: 10 }}
                >
                  Phone No.
                </th>
                <th
                  className="hidden sm:table-cell px-4 py-2 sm:py-3 sm:px-6 text-center pl-5"
                  style={{ paddingLeft: 10, paddingRight: 10 }}
                >
                  Date
                </th>
                <th
                  className="hidden sm:table-cell px-4 py-2 sm:py-3 sm:px-6 text-center pl-5"
                  style={{ paddingLeft: 10, paddingRight: 10 }}
                >
                  Time
                </th>
                <th
                  className="hidden sm:table-cell px-4 py-2 sm:py-3 sm:px-6 text-center pl-5"
                  style={{ paddingLeft: 10, paddingRight: 10 }}
                >
                  Status
                </th>
               
              </tr>
            </thead>
            <tbody className="text-zinc-500 px-10 text-center">
              {filteredData && filteredData.length > 0
                ? filteredData.map((item, index) => (
                    <tr
                      key={index}
                      className="hover:text-black cursor-pointer hover:bg-green-50/50 capitalize"
                     
                    >
                      <td
                        className=" py-2 sm:py-3 "
                        style={{ paddingLeft: 10 }}
                      >
                        {item?.name}
                      </td>
                      <td
                        className=" py-2 sm:py-3 "
                        style={{ paddingLeft: 10 }}
                      >
                        {item?.email}
                      </td>
                      <td
                        className=" py-2 sm:py-3 "
                        style={{ paddingLeft: 10 }}
                      >
                        {item?.phone}
                      </td>
                      <td
                        className="hidden sm:table-cell  py-2 sm:py-3 "
                        style={{ paddingLeft: 10 }}
                      >
                        {FormateDate(item?.createdAt)}
                      </td>
                      <td
                        className="hidden sm:table-cell  py-2 sm:py-3 "
                        style={{ paddingLeft: 10 }}
                      >
                        {item?.time}
                      </td>
                     
                      <td
                        className="hidden sm:table-cell  py-2 sm:py-3 "
                        style={{ paddingLeft: 10 }}
                      >
                        <span
                          className={
                            item?.status === "active"
                              ? "text-green-500"
                              : "text-red-500"
                          }
                        >
                          {item?.status}
                        </span>
                      </td>
                    </tr>
                  ))
                : [...Array(5)].map((_, key) => (
                    <tr key={key}>
                      <td className="px-4 py-2 sm:py-3 sm:p-3">
                        <SkeletonContainer data="loading..." />
                      </td>
                      <td className="px-4 py-2 sm:py-3 sm:p-3">
                        <SkeletonContainer
                          data="loading..."
                          className="w-4/5 ml-2"
                        />
                      </td>
                      <td className="px-4 py-2 sm:py-3 sm:p-3">
                        <SkeletonContainer
                          data="loading..."
                          className="w-4/5 ml-2"
                        />
                      </td>
                      <td className="px-4 py-2 sm:py-3 sm:p-3">
                        <SkeletonContainer
                          data="loading..."
                          className="w-4/5 ml-2"
                        />
                      </td>
                      <td className="px-4 py-2 sm:py-3 sm:p-3">
                        <SkeletonContainer
                          data="loading..."
                          className="w-4/5 ml-2"
                        />
                      </td>
                      <td className="px-4 py-2 sm:py-3 sm:p-3">
                        <SkeletonContainer
                          data="loading..."
                          className="w-4/5 ml-2"
                        />
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>

        <div className="py-5 px-10 fixed bottom-1 flex justify-center left-7 bg-white w-full h-16">
        <div className="flex justify-center items-center my-4">
          <button
            className="px-6 py-2 mx-1 rounded-lg text-white bg-green-900 font-semibold"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span className="px-4 text-green-900 font-bold">{`${currentPage} / ${totalPages}`}</span>
          <button
            className="px-6 py-2 mx-1  rounded-lg text-white bg-green-900  font-semibold"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
        </div>
      </div>
    </Layout>
  );
};

export default Appointment ;

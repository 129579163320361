import { GiAbstract051, GiStomach } from "react-icons/gi";
import { BiInjection } from "react-icons/bi";
import { PiPackage } from "react-icons/pi";
import { TbSmartHome } from "react-icons/tb";
import { IoCalendarNumberOutline } from "react-icons/io5";
import { CgNotes } from "react-icons/cg";
import { FaUserDoctor } from "react-icons/fa6";
import guts from "../assets/guts.png"; 
export const Icon = {
  dashboard: <TbSmartHome />,
  daignosis: <BiInjection />,
  lead: <GiAbstract051 />,
  orders: <PiPackage />,
  consultant: <FaUserDoctor />,
  calender: <IoCalendarNumberOutline />,
  appointment: <CgNotes />,
  guts: <GiStomach />
// guts: (
//     <img
//       src={guts}
//       alt="Guts Icon"
//       style={{ width: "24px", height: "24px" }}
//     />
//   ),
};

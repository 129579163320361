import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import axios from "axios";
import { apiurl } from "../config/config";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchData } from "../redux/slices/diagnosPatient";
import SkeletonContainer from "../routes/skeleton";
import { useFormatOnlyDate } from "../hooks/useFormatDate";
import moment from "moment";
import { GoDownload } from "react-icons/go";
import ExportDataHandler from "../components/exportDataHandler";
import ReactPaginate from "react-paginate";
import { FaPencilAlt } from "react-icons/fa";
import { MdDelete } from "react-icons/md";
import ConfirmModal from "./confirmModal";
import { IoSearchOutline } from "react-icons/io5";
const Gut = () => {
  const [gutsData, setgutsData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [date, setDate] = useState({ from: "", till: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState("50");
  const [showModal, setShowModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [search, setSearch] = useState();
  const [totalPages, setTotalPages] = useState(0);
  console.log("currentPage", currentPage);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const config = {
          method: "get",
          maxBodyLength: Infinity,
          url: `${apiurl}/api/v1/gut/getall?page=${currentPage}&limit=${itemsPerPage}`,
          headers: {},
        };
        const response = await axios.request(config);
        if (response.data.success) {
          const data = response.data.data || [];
          setgutsData(data);
          setFilteredData(data);
          setTotalPages(response.data.pagination.totalPages);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [currentPage]);
  console.log("filterData", filteredData);

  const getCurrentDate = () => {
    const currentDate = new Date();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    const padZero = (num) => (num < 10 ? `0${num}` : num);
    let formattedDate = `${padZero(day)}-${padZero(month)}-${year}`;
    return formattedDate.split("-").reverse().join("-");
  };

  const FormateDate = (date) => {
    return moment(date).format("MMMM Do YYYY");
  };

  const dateHandler = (e) => {
    const { name, value } = e.target;
    setDate({ ...date, [name]: value });
  };
  useEffect(() => {
    if (date.from && date.till) {
      FilterHandler();
    }
  }, [date]);

  const FilterHandler = () => {
    if (gutsData.length > 0) {
      const fromDate = new Date(date.from);
      const tillDate = new Date(date.till);

      const filtered = gutsData.filter((item) => {
        const gutsData = new Date(
          moment(item?.diagonse_date).format("YYYY-MM-DD")
        );
        return gutsData >= fromDate && gutsData <= tillDate;
      });

      setFilteredData(filtered);
      setCurrentPage(0);
    }
  };
  // const handleSelectRow = (id) => {
  //   setSelectedRows((prevSelectedRows) =>
  //     prevSelectedRows.includes(id)
  //       ? prevSelectedRows.filter((rowId) => rowId !== id)
  //       : [...prevSelectedRows, id]
  //   );
  // };

  // const handleDeleteSelected = () => {
  //   if (selectedRows.length > 0) {
  //     setShowModal(true); // Show the confirmation modal for multiple deletions
  //   }
  // };

  // const confirmDelete = () => {
  //   if (userIdToDelete) {
  //     axios
  //       .post(
  //         `https://api.thehealthspanco.com/api/v1/diagnose/delete/${userIdToDelete}`
  //       )
  //       .then(() => {
  //         setDiagnosData((prevData) =>
  //           prevData.filter((item) => item._id !== userIdToDelete)
  //         );
  //         setFilteredData((prevData) =>
  //           prevData.filter((item) => item._id !== userIdToDelete)
  //         );
  //         setShowModal(false);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         setShowModal(false);
  //       });
  //   } else {
  //     selectedRows.forEach((userId) => {
  //       axios
  //         .post(
  //           `https://api.thehealthspanco.com/api/v1/diagnose/delete/${userId}`
  //         )
  //         .then(() => {
  //           setDiagnosData((prevData) =>
  //             prevData.filter((item) => item._id !== userId)
  //           );
  //           setFilteredData((prevData) =>
  //             prevData.filter((item) => item._id !== userId)
  //           );
  //         })
  //         .catch((error) => console.log(error));
  //     });
  //     setSelectedRows([]);
  //     setShowModal(false);
  //   }
  // };
  const confirmDelete = () => {
    let config = {
      method: "delete",
      maxBodyLength: Infinity,
      url: `https://api.thehealthspanco.com/api/v1/gut/delete/${userIdToDelete}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log("Delete successful:", response.data);

        // Filter out the deleted user from the local frontend state
        setFilteredData((prevData) =>
          prevData.filter((user) => user._id !== userIdToDelete)
        );

        // Hide the confirmation modal
        setShowModal(false);
      })
      .catch((error) => {
        console.error("Error deleting the user:", error);
      });
  };

  const handleDelete = (userId) => {
    setUserIdToDelete(userId);
    setShowModal(true);
  };

  const cancelDelete = () => {
    setShowModal(false);
  };

  // Handle page click

  const ExportPayload = (data) => {
    const payload = Array.isArray(data) ? data : [data];
    return payload.map((item) => {
      return {
        _id: item._id,
        name: item.name,
        email: item.email,
        phone: item.phone,
        date: item.date,
        age: item.age,
        gender: item.gender,
        weight: item.weight,
        height: item.height,
        health_goals: Array.isArray(item.health_goals)
          ? item.health_goals.join(", ")
          : "",
        bmi_category: item.bmi?.category || "",
        bmi_range: item.bmi?.range || "",
        gut_symptom: Array.isArray(item.gut_symptom)
          ? item.gut_symptom.join(", ")
          : "",
        exercise: item.exercise || "",
        phyiscal_activity: item.phyiscal_activity || "",
        medical_condition: item.medical_condition || "",
        root_cause: Array.isArray(item.root_cause)
          ? item.root_cause.join(", ")
          : "",
        bmr: item.bmr || "",
        idealWeight: item.idealWeight || "",
        weightToLose: item.weightToLose || "",
        weightLossDuration: item.weightLossDuration || "",
        idealWaistSize: item.idealWaistSize || "",
        dailyCalorieIntake: item.dailyCalorieIntake || "",
        diagnosed_conditions: Array.isArray(item.diagnosed_conditions)
          ? item.diagnosed_conditions.join(", ")
          : "",
        eating_habits: item.eating_habits || "",
        allergic: Array.isArray(item.allergic) ? item.allergic.join(", ") : "",
        medication: item.medication || "",
        sleep: item.sleep || "",
        diagonse_date: item.diagonse_date || "",
        description: item.description || "",
        createdAt: item.createdAt || "",
        updatedAt: item.updatedAt || "",
      };
    });
  };

  // const handleSearch = (e) => {
  //   const newSearch = e.target.value;
  //   setSearch(newSearch);

  //   if (newSearch === "") {
  //     setFilteredData(diagnosData);
  //   } else {

  //     axios
  //       .get(`https://api.thehealthspanco.com/api/v1/diagnose/get/search/${newSearch}`)
  //       .then((response) => {
  //         if (response.data.success === true) {

  //           setFilteredData(response.data.data || []);
  //         }
  //       })
  //       .catch((error) => {
  //         console.error("Search API Error:", error);
  //       });
  //   }
  // };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  return (
    <Layout>
      <div className="w-full mt-2 h-full">
        <div className="my-3 mt-6 flex items-center relative ">
          {/* {selectedRows.length > 0 && (
            <div className="flex items-center mt-3">
              <button
                className="text-white bg-green-800 px-2 py-1 rounded cursor-pointer ms-2"
                onClick={handleDeleteSelected}
              >
                Delete
              </button>
            </div>
          )} */}

          {showModal && (
            <ConfirmModal
              message="Are you sure you want to delete this user?"
              onConfirm={confirmDelete} 
              onCancel={cancelDelete} 
            />
          )}
          <div className="w-full flex justify-end items-center mt-3 gap-4 px-8">
            {/* Search Bar
            <div className="bg-white border border-green-800 flex items-center py-1 px-1.5 rounded-lg w-full">
              <div className="flex items-center gap-3 w-full">
                <IoSearchOutline className="text-lg text-zinc-600" />
                <input
                  placeholder="Search"
                  value={search}
                  className="bg-transparent outline-none w-full"
                  onChange={handleSearch} 
                />
              </div>
            </div> */}

            <div className="flex gap-3 items-center">
              <input
                placeholder="From"
                name="from"
                type="date"
                max={getCurrentDate()}
                className="outline-none border border-green-800 rounded-lg text-xs px-3 py-2 w-full md:w-auto"
                onChange={dateHandler}
                value={date.from}
              />
              <input
                placeholder="Till"
                name="till"
                type="date"
                min={date.from}
                max={getCurrentDate()}
                className="outline-none border border-green-800 rounded-lg text-xs px-3 py-2 w-full md:w-auto"
                onChange={dateHandler}
                value={date.till}
              />
              <button
                className={`border p-2 px-4 rounded-md ${
                  date.from && date.till && filteredData.length > 0
                    ? "bg-green-50 border-green-800 text-green-800"
                    : "border-green-800 text-green-800"
                }`}
                onClick={() => ExportDataHandler(ExportPayload(filteredData))}
                disabled={!(date.from && date.till && filteredData.length > 0)}
              >
                <GoDownload />
              </button>
            </div>
          </div>
        </div>
        <div className="h-full overflow-scroll text-xs md:text-sm lg:text-base px-5 mt-3 pb">
          <table className="border-collapse w-full relative table-auto overflow-auto border ">
            <thead className="bg-green-900 border-y text-white lg:text-base text-xs  top-0 z-50">
              <tr>
                {/* <th className="px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">
                  Select
                </th> */}
                <th className="px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">
                  Name
                </th>
                <th className="px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">
                  Email
                </th>
                <th className="px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">
                  Phone
                </th>
                <th className="md:table-cell px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">
                  Date
                </th>
                <th className="md:table-cell px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">
                  B.S.T
                </th>
                <th className="md:table-cell px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">
                  B.M.I
                </th>
                <th className="md:table-cell px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">
                  Purchase
                </th>
                <th className="md:table-cell px-2 py-1 sm:px-4 md:px-6 lg:px-8 text-center">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filteredData && filteredData.length > 0
                ? filteredData.map(
                    (user) => (
                      console.log("date", user),
                      (
                        <tr key={user._id}>
                          {/* <td className="px-2 py-1 sm:px-4 md:px-6 text-center">
                        <input
                          type="checkbox"
                          className="scale-125"
                          checked={selectedRows.includes(user._id)}
                          onChange={() => handleSelectRow(user._id)}
                        />
                      </td> */}
                          <td className="px-2 py-1 sm:px-4 md:px-6 text-center cursor-pointer">
                            {user?.name}
                          </td>
                          <td className="px-2 py-1 sm:px-4 md:px-6 text-center cursor-pointer">
                            {user?.email}
                          </td>
                          <td className="px-2 py-1 sm:px-4 md:px-6 text-center">
                            {user?.phone}
                          </td>
                          <td className="md:table-cell px-2 py-1 sm:px-4 md:px-6 text-center">
                            {FormateDate(user?.createdAt)}
                          </td>
                          <td className="px-2 py-1 sm:px-4 md:px-6 text-center">
                            {user?.bst}
                          </td>
                          <td className="md:table-cell px-2 py-1 sm:px-4 md:px-6 text-center">
                            {user?.bmi?.range}
                          </td>
                          <td className="md:table-cell px-2 py-1 sm:px-4 md:px-6 text-center">
                            <span
                              className={
                                user?.orderDetails?.purchase
                                  ? "text-green-500"
                                  : "text-red-500"
                              }
                            >
                              {user?.orderDetails?.purchase ? "Yes" : "No"}
                            </span>
                          </td>
                          <td className="px-2 py-1 sm:px-4 md:px-6 text-center">
                            <button
                              // onClick={() =>
                              //   navigate(`/diagnosis/${user._id}`, {
                              //     state: { patient: user },
                              //   })
                              // }
                              className="text-black"
                            >
                              <FaPencilAlt />
                            </button>
                            <button
                              onClick={() => handleDelete(user._id)}
                              className="hover:text-red-600 ml-3"
                            >
                              <MdDelete />
                            </button>
                          </td>
                        </tr>
                      )
                    )
                  )
                : [...Array(5)].map((_, key) => (
                    <tr key={key}>
                      <td className="px-4 py-2 sm:py-3 sm:p-3">
                        <SkeletonContainer data="loading..." />
                      </td>
                      <td className="px-4 py-2 sm:py-3 sm:p-3">
                        <SkeletonContainer
                          data="loading..."
                          className="w-4/5 ml-2"
                        />
                      </td>
                      <td className="px-4 py-2 sm:py-3 sm:p-3">
                        <SkeletonContainer
                          data="loading..."
                          className="w-4/5 ml-2"
                        />
                      </td>
                      <td className="px-4 py-2 sm:py-3 sm:p-3">
                        <SkeletonContainer
                          data="loading..."
                          className="w-4/5 ml-2"
                        />
                      </td>
                      <td className="px-4 py-2 sm:py-3 sm:p-3">
                        <SkeletonContainer
                          data="loading..."
                          className="w-4/5 ml-2"
                        />
                      </td>
                      <td className="px-4 py-2 sm:py-3 sm:p-3">
                        <SkeletonContainer
                          data="loading..."
                          className="w-4/5 ml-2"
                        />
                      </td>
                      <td className="px-4 py-2 sm:py-3 sm:p-3">
                        <SkeletonContainer
                          data="loading..."
                          className="w-4/5 ml-2"
                        />
                      </td>
                      <td className="px-4 py-2 sm:py-3 sm:p-3">
                        <SkeletonContainer
                          data="loading..."
                          className="w-4/5 ml-2"
                        />
                      </td>
                      <td className="px-4 py-2 sm:py-3 sm:p-3">
                        <SkeletonContainer
                          data="loading..."
                          className="w-4/5 ml-2"
                        />
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>

        <div className="py-5 px-10 fixed bottom-1 flex justify-center left-7 bg-white w-full h-16">
          <div className="flex justify-center items-center my-4">
            <button
              className="px-6 py-2 mx-1 rounded-lg text-white bg-green-900 font-semibold"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="px-4 text-green-900 font-bold">{`${currentPage} / ${totalPages}`}</span>
            <button
              className="px-6 py-2 mx-1  rounded-lg text-white bg-green-900  font-semibold"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Gut;

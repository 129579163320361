import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import SkeletonContainer from "../routes/skeleton";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { apiurl } from "../config/config";
import { MdDelete, MdEdit } from "react-icons/md";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ConfirmModal from "./confirmModal";

const TeamList = () => {
  const userData = useSelector((state) => state?.userData);
  const userId = userData?._id;
  const userRole = userData?.role; 
  const navigate = useNavigate();
  const [teamlist, setTeamist] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
   const [totalPages,setTotalPages] = useState(0);
  const itemsPerPage = 50; 
  useEffect(() => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${apiurl}/api/v1/user/getall?page=${currentPage}&limit=${itemsPerPage}`,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        if (response.data.success === true) {
          setTeamist(response.data.users);
          setTotalPages(response.data.pagination.totalPages)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleDelete = (Id, role) => {
    if (userRole === "master" || (userRole !== "master" && Id === userId)) {
      let data = "";

      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `https://api.thehealthspanco.com/api/v1/user/delete/${Id}`,
        headers: {},
        data: data,
      };

      axios
        .request(config)
        .then((response) => {
          console.log(JSON.stringify(response.data));

          setTeamist((prevList) => prevList.filter((user) => user._id !== Id));
          toast.success("User deleted successfully.");
        })
        .catch((error) => {
          console.log(error);
          toast.error("Failed to delete the user.");
        });
    } else {
      toast.error("You do not have permission to delete this user.");
    }
  };
  const confirmDelete = () => {
    if (selectedUser) {
      handleDelete(selectedUser._id);
      setShowModal(false);
    }
  };

  const cancelDelete = () => {
    setShowModal(false);
    setSelectedUser(null);
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };
  return (
    <Layout>
      
      <div className="h-[75%] overflow-scroll text-xs md:text-sm lg:text-base px-5 ">
        <table className="border-collapse w-full relative table-auto overflow-auto border mt-2">
          <thead className="bg-green-900 border-y text-white lg:text-base text-xs sticky top-0">
            <tr>
              <th className="px-4 py-2 sm:py-3 sm:px-6 text-center pl-5">Name</th>
              <th className="px-4 py-2 sm:py-3 sm:px-6 text-center pl-5">Email</th>
              <th className="px-4 py-2 sm:py-3 sm:px-6 text-center pl-5">Phone No.</th>
              <th className="px-4 py-2 sm:py-3 sm:px-6 text-center pl-5">Age</th>
              <th className="px-4 py-2 sm:py-3 sm:px-6 text-center pl-5">Role</th>
              <th className="px-4 py-2 sm:py-3 sm:px-6 text-center pl-5">Status</th>
              <th className="px-4 py-2 sm:py-3 sm:px-6 text-center pl-5">Action</th>
            </tr>
          </thead>
          <tbody className="text-zinc-500 px-10 ">
          {teamlist.length > 0
              ? teamlist
                  .filter((user) => user._id !== userId)
                  .map((item, index) => (
                    <tr
                      key={index}
                      className="hover:text-black cursor-pointer hover:bg-green-50/50 capitalize"
                    >
                      <td
                        className="py-2 sm:py-3 text-center"
                        style={{ paddingLeft: 10 }}
                        onClick={() => navigate(`/team/${item._id}`)}
                      >
                        {item?.firstname} {item?.lastname}
                      </td>
                      <td className="py-2 sm:py-3 text-center" style={{ paddingLeft: 10 }}>
                        {item?.email}
                      </td>
                      <td className="py-2 sm:py-3 text-center" style={{ paddingLeft: 10 }}>
                        {item?.phone}
                      </td>
                      <td className="py-2 sm:py-3 text-center" style={{ paddingLeft: 10 }}>
                        {item?.age}
                      </td>
                      <td className="py-2 sm:py-3 text-center" style={{ paddingLeft: 10 }}>
                        {item?.role}
                      </td>
                      <td
                        className={`py-2 sm:py-3 text-center ${
                          item?.status === "active"
                            ? "text-green-500"
                            : item?.status === "pending"
                            ? "text-yellow-500"
                            : "text-red-500"
                        }`}
                        style={{ paddingLeft: 10 }}
                      >
                        {item?.status}
                      </td>
                      <td className="py-2 sm:py-3 text-xl text-center text-black">
                        <div className="flex items-center pe-4">
                          <MdEdit className="m-auto" onClick={() => navigate(`/team/${item._id}/edit`)} />
                          <MdDelete
                            onClick={() => {
                              if (userRole === "master") {
                                setSelectedUser(item);
                                setShowModal(true);
                              } else {
                                handleDelete(item._id);
                              }
                            }}
                          />
                        </div>
                      </td>
                    </tr>
                  ))
                  : [...Array(5)].map((_, key) => (
                  <tr key={key}>
                    <td className="px-4 py-2 sm:py-3 sm:p-3">
                      <SkeletonContainer
                        data="loading..."
                        className="w-4/5 ml-2"
                      />
                    </td>
                    <td className="px-4 py-2 sm:py-3 sm:p-3">
                      <SkeletonContainer
                        data="loading..."
                        className="w-4/5 ml-2"
                      />
                    </td>
                    <td className="px-4 py-2 sm:py-3 sm:p-3">
                      <SkeletonContainer
                        data="loading..."
                        className="w-4/5 ml-2"
                      />
                    </td>
                    <td className="px-4 py-2 sm:py-3 sm:p-3">
                      <SkeletonContainer
                        data="loading..."
                        className="w-4/5 ml-2"
                      />
                    </td>
                    <td className="px-4 py-2 sm:py-3 sm:p-3">
                      <SkeletonContainer
                        data="loading..."
                        className="w-4/5 ml-2"
                      />
                    </td>
                    <td className="px-4 py-2 sm:py-3 sm:p-3">
                      <SkeletonContainer
                        data="loading..."
                        className="w-4/5 ml-2"
                      />
                    </td>
                    <td className="px-4 py-2 sm:py-3 sm:p-3">
                      <SkeletonContainer
                        data="loading..."
                        className="w-4/5 ml-2"
                      />
                    </td>
                    
                  </tr>
                ))}
          </tbody>
        </table>
        {showModal && (
        <ConfirmModal
          message={`Are you sure you want to delete ${selectedUser?.firstname} ${selectedUser?.lastname}?`}
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
        />
      )}
      <div className="py-5 px-10 fixed bottom-1 flex justify-center left-7 bg-white w-full h-16">
        <div className="flex justify-center items-center my-4">
          <button
            className="px-6 py-2 mx-1 rounded-lg text-white bg-green-900 font-semibold"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            Previous
          </button>
          <span className="px-4 text-green-900 font-bold">{`${currentPage} / ${totalPages}`}</span>
          <button
            className="px-6 py-2 mx-1  rounded-lg text-white bg-green-900  font-semibold"
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Next
          </button>
        </div>
        </div>
      </div>
    </Layout>
  );
};

export default TeamList;

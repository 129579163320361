import { ToastContainer } from "react-toastify";
import "./App.css";
import RoutesContainer from "./routes";
import React from "react";

function App() {
  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
      />
      <RoutesContainer />
    </React.Fragment>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import Layout from "../components/layout";
import SkeletonContainer from "../routes/skeleton";
import { fetchData } from "../redux/slices/consultantSlice";
import axios from "axios";
import { apiurl } from "../config/config";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import useFormatDate from "../hooks/useFormatDate";
import moment from "moment";
import { GoDownload } from "react-icons/go";
import ExportDataHandler from "../components/exportDataHandler";
const Consultant = () => {
  const [consultData, setConsultData] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [filteredData, setFilteredData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 50;
  const FormateDate = (date) => moment(date).format("MMMM Do YYYY");
  const [date, setDate] = useState({
    from: "",
    till: "",
  });
  useEffect(() => {
    axios
      .get(
        `${apiurl}/api/v1/consultation/getAll?page=${currentPage}&limit=${itemsPerPage}`
      )
      .then((response) => {
        if (response.data.success === true) {
          setConsultData(response.data.data);
          setFilteredData(response.data.data);
          setTotalPages(response.data.pagination.totalPages);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [currentPage]);

  // const startIndex = currentPage * itemsPerPage;
  // const paginatedData = filteredData.slice(
  //   startIndex,
  //   startIndex + itemsPerPage
  // );

  // const handlePageClick = (event) => {
  //   setCurrentPage(event.selected);
  // };
  const getCurrentDate = () => {
    const currentDate = new Date();
    return moment(currentDate).format("YYYY-MM-DD");
  };
  const dateHandler = (e) => {
    const { name, value } = e.target;
    const updatedDate = { ...date, [name]: value };
    setDate(updatedDate);
    filterDataByDate(updatedDate);
  };
  const ExportPayload = (data) => {
    const payload = Array.isArray(data) ? data : [data];
    return payload.map((item) => ({
      _id: item._id,
      name: item.name,
      email: item.email,
      phone: item.phone,
      date: item.date,
    }));
  };

  const filterDataByDate = (dateRange) => {
    const { from, till } = dateRange;
    if (from && till) {
      const filtered = consultData.filter((item) => {
        const itemDate = moment(item.date, "YYYY-MM-DD");
        return itemDate.isBetween(from, till, "days", "[]");
      });
      setFilteredData(filtered);
    } else {
      setFilteredData(consultData);
    }
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };
  return (
    <Layout>
      <div className="w-full mt-2 h-full ">
        <div className="my-3 mt-6 flex justify-end relative ">
          <div className="flex gap-3 max-[375px]:justify-between">
            <input
              placeholder="from"
              name="from"
              type="date"
              max={getCurrentDate()}
              className="outline-none border border-green-800 rounded-lg md:text-sm text-xs px-0.5 max-[375px]:w-full max-[375px]:px-3 sm:px-2 lg:px-5 py-2 sm:w-auto"
              onChange={dateHandler}
              value={date.from}
            />
            <input
              placeholder="till"
              name="till"
              type="date"
              min={date.from}
              max={getCurrentDate()}
              className="outline-none border border-green-800 rounded-lg md:text-sm text-xs px-0.5 max-[375px]:w-full max-[375px]:px-3 sm:px-2 lg:px-5 py-2 sm:w-auto"
              onChange={dateHandler}
              value={date.till}
            />
          </div>
          <button
            className={`border p-2 px-4 ms-2 rounded-md ${
              date.from && date.till && filteredData.length > 0
                ? "bg-green-50 border-green-800 text-green-800"
                : "border-green-800 text-green-800"
            }`}
            onClick={() => ExportDataHandler(ExportPayload(filteredData))}
            disabled={!(date.from && date.till && filteredData.length > 0)}
          >
            <GoDownload />
          </button>
        </div>

        <div className="h-full overflow-scroll text-xs md:text-sm lg:text-base px-5 pb-32">
          <table className="border-collapse w-full relative table-auto overflow-auto border">
            <thead className="bg-green-900 border-y text-white lg:text-base text-xs sticky top-0">
              <tr>
                <th className="px-4 py-2 sm:py-3 sm:px-6 text-center">Date</th>
                <th
                  className="px-4 py-2 sm:py-3 sm:px-6 text-center  "
                  style={{ paddingLeft: 15 }}
                >
                  Name
                </th>
                <th
                  className="px-4 py-2 sm:py-3 sm:px-6 text-center"
                  style={{ paddingLeft: 15 }}
                >
                  Email
                </th>
                <th
                  className="px-4 py-2 sm:py-3 sm:px-6 text-center"
                  style={{ paddingLeft: 15 }}
                >
                  Phone No.
                </th>
                <th
                  className="px-4 py-2 sm:py-3 sm:px-6 text-center"
                  style={{ paddingLeft: 15 }}
                >
                  Status
                </th>
              </tr>
            </thead>
            <tbody className="text-zinc-500 px-10 ">
              {filteredData && filteredData?.length > 0
                ? filteredData.map((item, index) => {
                    return (
                      <tr
                        key={index}
                        className="text-center hover:text-black cursor-pointer hover:bg-green-50/50 capitalize"
                        onClick={() =>
                          navigate(`/consultant/${item?._id}`, {
                            state: { patient: item },
                          })
                        }
                      >
                        <td className="px-4 py-2 sm:py-3 sm:px-6 ">
                          {item?.date && FormateDate(item?.date)}
                        </td>
                        <td
                          className="px-4 py-2 sm:py-3 sm:px-6 pl-5 "
                          style={{ paddingLeft: 15 }}
                        >
                          {item?.name}
                        </td>
                        <td
                          className="px-4 py-2 sm:py-3 sm:px-6 "
                          style={{ paddingLeft: 15 }}
                        >
                          {item?.email}
                        </td>
                        <td
                          className="px-4 py-2 sm:py-3 sm:px-6"
                          style={{ paddingLeft: 15 }}
                        >
                          {item?.phone}
                        </td>
                        <td
                          className={`px-4 py-2 sm:py-3 sm:px-6  ${
                            item?.status === "active"
                              ? "text-green-500"
                              : item?.status === "pending"
                              ? "text-yellow-500"
                              : "text-red-500"
                          }`}
                          style={{ paddingLeft: 15 }}
                        >
                          {item?.status}
                        </td>
                      </tr>
                    );
                  })
                : [...Array(5)].map((_, key) => (
                    <tr>
                      <td className="px-4 py-2 sm:py-3 sm:px-6">
                        <SkeletonContainer data="loading..." />
                      </td>
                      <td className="px-4 py-2 sm:py-3 sm:px-6">
                        <SkeletonContainer
                          data="loading..."
                          className="w-4/5 ml-2"
                        />
                      </td>
                      <td className="px-4 py-2 sm:py-3 sm:px-6">
                        <SkeletonContainer
                          data="loading..."
                          className="w-4/5 ml-2"
                        />
                      </td>
                      <td className="px-4 py-2 sm:py-3 sm:px-6">
                        <SkeletonContainer
                          data="loading..."
                          className="w-4/5 ml-2"
                        />
                      </td>
                      <td className="px-4 py-2 sm:py-3 sm:px-6">
                        <SkeletonContainer
                          data="loading..."
                          className="w-4/5 ml-2"
                        />
                      </td>
                    </tr>
                  ))}
            </tbody>
          </table>
        </div>
        <div className="py-5 px-10 fixed bottom-1 flex justify-center left-7 bg-white w-full h-16">
          <div className="flex justify-center items-center my-4">
            <button
              className="px-6 py-2 mx-1 rounded-lg text-white bg-green-900 font-semibold"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span className="px-4 text-green-900 font-bold">{`${currentPage} / ${totalPages}`}</span>
            <button
              className="px-6 py-2 mx-1  rounded-lg text-white bg-green-900  font-semibold"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Consultant;
